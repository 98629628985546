angular.module('fingerink')
.directive('ngFocusModel', function () {
    return function (scope, element) {

        var focusListener = function () {
            scope.hasFocus = true;
            scope.$digest();
        };

        var blurListener = function () {
            scope.hasFocus = false;
            scope.$digest();
        };


        element[0].addEventListener('focus', focusListener, true);
        element[0].addEventListener('blur', blurListener, true);
    };
});
